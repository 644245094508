import _typeof from "/Volumes/workspace/project/twiboss/frontend/twi-super-admin/node_modules/@babel/runtime/helpers/esm/typeof";
import "core-js/modules/es.array.map.js";
var channelNameList = ['橙券', '福禄', '惠享派', '自营', '多鲸V1', '多鲸V2', '星凯', '彦浩', '伯爵', '星空云', '悠品', '无忧', '万图拍', '椰子', '惠生活', '昊阳', '尚投', '南京硅通', '南京力方', '万图拍2', '品创', '新美']; // 目前其 value 是 channelNameList 依次递升的
// 如有特例，channelNameList 中 item 可放对象以自定义 { label: 'xx', vlaue: 999 }

export var channelList = channelNameList.map(function (e, i) {
  return _typeof(e) === 'object' ? e : {
    label: e,
    value: i + 1
  };
});